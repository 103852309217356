@tailwind base;
@tailwind components;
@tailwind utilities;

input {
    @apply outline-none focus:outline-none;
}

button {
    @apply outline-none focus:outline-none;
}

.tui-editor-contents > table {
    @apply w-full table-fixed break-all;
}

.btn {
    @apply flex justify-center items-center;
    @apply py-2 px-4 rounded-md;
    @apply outline-none focus:outline-none;
    @apply cursor-pointer disabled:cursor-default;
    @apply disabled:opacity-50 disabled:ring-0;
}

.btn-purple {
    @apply text-white hover:text-purple-50;
    @apply bg-purple-500 hover:bg-purple-700 disabled:bg-purple-500;
    @apply hover:ring-2 hover:ring-offset-2 hover:ring-purple-500;
}

.btn-red {
    @apply text-white hover:text-red-50;
    @apply bg-red-500 hover:bg-red-700 disabled:bg-red-500;
    @apply hover:ring-2 hover:ring-offset-2 hover:ring-red-500;
}

.btn-blue {
    @apply text-white hover:text-blue-50;
    @apply bg-blue-500 hover:bg-blue-700 disabled:bg-blue-500;
    @apply hover:ring-2 hover:ring-offset-2 hover:ring-blue-500;
}

.btn-gray {
    @apply text-gray-600 hover:text-gray-100;
    @apply bg-gray-200 hover:bg-gray-400 disabled:bg-gray-400;
    @apply hover:ring-2 hover:ring-offset-2 hover:ring-gray-400;
}

.btn-white {
    @apply border border-gray-300;
    @apply text-gray-700;
    @apply bg-white hover:bg-gray-300 disabled:bg-gray-300;
    @apply hover:ring-2 hover:ring-offset-2 hover:ring-gray-300;
}

.layer {
    @apply flex flex-col;
    @apply px-8 my-4;
}

.layer-title {
    @apply flex;
    @apply px-6 my-4;
}

.layer-title-text {
    @apply font-medium text-xl;
}

select {
    @apply outline-none focus:outline-none;
    /* text-align-last: center !important; */
}

[role='schedule-hour'],
[role='schedule-minute'] {
    appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: none;
}
